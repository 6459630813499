<script setup lang="ts">
  const pageInfo = usePageInfoStore();
</script>

<template>
  <div class="main-container footer-bottom-container">
    <div class="text-copyright-container">
      <span class="text-copyright">
        Все материалы данного сайта являются объектами авторского права (в том числе дизайн). Запрещается копирование, распространение (в том числе путем копирования на другие сайты и ресурсы в Интернете) или любое иное использование информации и объектов без предварительного письменного согласия правообладателя. Указание ссылки на источник информации является обязательным.
      </span>
      <span class="text-copyright">
        Материалы, размещенные на данной странице, носят информационный характер и предназначены для ознакомительных целей. Посетители сайта не должны использовать их в качестве медицинских рекомендаций. Определение диагноза и выбор методики лечения остается исключительной прерогативой вашего лечащего врача! Ист Клиника не несёт ответственности за возможные негативные последствия, возникшие в результате использования информации, размещенной на сайте.
      </span>
      <span class="text-copyright">
        Ист Клиника <NuxtLink :to="{name: 'CookiesPolicy'}" style="text-decoration-line: underline; color: #878FA2 !important;">использует файлы «cookie»</NuxtLink>, с целью персонализации сервисов и повышения удобства пользования веб-сайтом. «Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта. Если вы не хотите использовать файлы «cookie», измените настройки браузера.
      </span>
      <span class="text-copyright">
        Информация, размещенная на сайте, не является публичной офертой. Актуальную информацию о ценах, акциях и предложениях уточняйте по телефону.
      </span>
    </div>
    <span class="text-warning">Есть противопо&shy;казания. посоветуйтесь с врачом</span>
    <span class="text-version">© 2012-{{ new Date().getFullYear() }} Ист Клиника (версия {{ useRuntimeConfig().public.version }}) · <NuxtLink :to="pageInfo.agreements?.personal.url" external target="_blank">{{ pageInfo.agreements?.personal.text }}</NuxtLink></span>
  </div>
</template>

<style scoped lang="scss">
.footer-bottom-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 40px;
}
.text-copyright-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.text-copyright {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.text-warning {
  color: #ACB4C7;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 5.1px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    hyphens: manual;
  }
}
.text-version {
  color: #666E81;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.08px;
  & a {
    text-decoration: none;
  }
  & a:hover {
    color : #2D7FF9 !important;
  }
}
</style>
