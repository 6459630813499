<script setup lang="ts">
  const pageInfoStore = usePageInfoStore();
  const route = useRoute();
  const router = useRouter();
  const { width } = useWindowSize();

  const headerStore = useHeaderStore();
  const searchPanelStore = useSearchPanelStore();
  const doctorsStore = useDoctorsStore();
  const { showHelpModal } = storeToRefs(useBookingStore());
  const { clinicsCount } = storeToRefs(useClinicsStore());

  const showMobileHamburgerMenu = ref(false);

  const toggleMobileHamburgerMenu = () => {
    showMobileHamburgerMenu.value = !showMobileHamburgerMenu.value;
    if (showMobileHamburgerMenu.value) {
      document.documentElement.style.overflowY = 'hidden';
      return;
    }
    document.documentElement.style.overflowY = 'initial';
  };

  const clickOnLogo = () => {
    if (showMobileHamburgerMenu.value) {
      showMobileHamburgerMenu.value = false;
      document.documentElement.style.overflowY = 'initial';
    }
  };

  const moveToPrev = () => {
    const prev = router.options.history.state.back;
    if (prev) {
      navigateTo(prev as string);
    } else {
      navigateTo('/vrachi');
    }
  };

  const showMobileSearch = computed(() => {
    return (
      (route.path.includes('/vrachi') || route.name === 'uslugi' || route.name === 'Disease') &&
      width.value < 1023 &&
      searchPanelStore.searchPanelIsVisible === false &&
      showMobileHamburgerMenu.value === false &&
      pageInfoStore.pageInfo?.type !== 'doctor'
    );
  });

  const showMobileCurrentDoctor = computed(() => {
    return (
      pageInfoStore.pageInfo?.type === 'doctor' &&
      width.value < 768 &&
      doctorsStore.doctorInfoIsVisible === false &&
      showMobileHamburgerMenu.value === false
    );
  });

  const isDoctorsLinkActive = computed(() => {
    return (
      route.name === 'Doctors' ||
      route.name === 'Neurologist' ||
      route.name === 'uslugi' ||
      route.name === 'Disease'
    );
  });

  watch(router.currentRoute, () => {
    showMobileHamburgerMenu.value = false;
    document.documentElement.style.overflowY = 'initial';
  });
</script>

<template>
  <header class="header">
    <div class="container main-container">
      <ClientOnly v-if="showMobileCurrentDoctor">
        <IconArrowLeft class="icon-doctor-arrow" @click="moveToPrev" />
      </ClientOnly>
      <div v-else class="hamburger-wrapper" @click.stop="toggleMobileHamburgerMenu">
        <IconClose v-if="showMobileHamburgerMenu" class="icon-hamburger" />
        <IconHamburgerMenu v-else class="icon-hamburger" />
      </div>
      <HeaderMobileSearch v-if="showMobileSearch" @click.stop="headerStore.openMobileSearchModal" />
      <ClientOnly v-else-if="showMobileCurrentDoctor">
        <HeaderMobileCurrentDoctor />
      </ClientOnly>
      <NuxtLink v-else to="/" @click="clickOnLogo">
        <IconLogo :font-controlled="false" filled class="logo" />
      </NuxtLink>
      <HeaderMobileSearchModal v-if="headerStore.showMobileSearchModal" />
      <div class="menu">
        <div class="menu-item relative">
          <NuxtLink to="/vrachi" :class="`${isDoctorsLinkActive ? 'doctors-active-link':'doctors-link'}`">
            Врачи
          </NuxtLink>
          <HeaderPopupMenuDoctors />
        </div>
        <span :class="`menu-item ${$route.name === 'ClinicPage' ? 'active-link':''}`">
          Клиники
          <ClientOnly>
            <span class="clinics-prompt">{{ clinicsCount }}</span>
          </ClientOnly>
        </span>
        <HeaderPopupMenuClinics />
        <NuxtLink to="/uslugi" class="menu-item" active-class="active-link">
          Услуги
        </NuxtLink>
        <NuxtLink to="/akczii" class="menu-item" active-class="active-link">
          Акции
        </NuxtLink>
        <NuxtLink to="/kontakty" class="menu-item" active-class="active-link">
          Контакты
        </NuxtLink>
        <span :class="`menu-item relative ${$route.name === 'Reviews' || $route.name === 'Vacancies' ? 'active-link':''}`">
          Ещё
          <div class="popup-more">
            <div class="popup-more-container">
              <NuxtLink to="/otzyvy" class="popup-more-link">Отзывы</NuxtLink>
              <NuxtLink to="/vakansii" class="popup-more-link">Работа в Ист Клинике</NuxtLink>
            </div>
          </div>
        </span>
      </div>
      <div class="contacts-container">
        <div class="phone-desktop-container">
          <NuxtLink :to="pageInfoStore.getLinkPhone" class="phone-text">
            {{ pageInfoStore.getFormattedMainPhone }}
          </NuxtLink>
          <span class="phone-time">08:00-21:00</span>
        </div>
        <NuxtLink :href="pageInfoStore.getLinkPhone" target="_blank" external class="phone-mobile-container">
          <IconPhoneHeader :font-controlled="false" filled class="icon-phone" />
        </NuxtLink>
        <NuxtLink
          v-show="!showMobileSearch && !showMobileCurrentDoctor"
          target="_blank"
          rel="nofollow"
          external
          :href="pageInfoStore.getWhatsAppPhone"
          class="whats-app-container"
        >
          <IconWhatsApp filled :font-controlled="false" class="icon-whats-app" />
        </NuxtLink>
      </div>
    </div>
  </header>
  <HeaderMobileHamburgerMenu v-if="showMobileHamburgerMenu" />
  <PatientHelpFormModal v-if="showHelpModal" />
</template>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: white;
}
.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  @media (max-width: 1023px) {
    height: 52px;
    padding: 8px 12px;
  }
  &:has(.mobile-search) {
    justify-content: center;
    gap: 0.5rem;
    height: 72px;
    padding: 8px 12px;
  }
}

.logo {
  width: 11.875rem;
  height: 1.5rem;
  @media (max-width: 1023px) {
    width: 9.938rem;
    margin-left: 35px;
  }
  @media (max-width: 345px) {
    margin: 0px;
  }
}

.menu {
  display: flex;
  align-items: center;
  @media (max-width: 1023px) {
    display: none;
  }
  &-item {
    padding: 11px 16px;
    color: #666E81;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    &:hover {
      border-radius: 1.5rem;
      background-color: #F5F6F9;
      cursor: pointer;
    }
  }
}
.hamburger-wrapper {
  padding: 0.5rem;
  cursor: pointer;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
}
.icon-hamburger {
  font-size: 1.5rem;
  color: #262633;
}
.popup-more {
  z-index: 100;
  display: none;
  position: absolute;
  top: 100%;
  padding-top: 16px;
  width: 204px;
  .menu-item:hover > &, &:hover {
    display: flex;
  }
}
.popup-more-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.12);
}
.popup-more-link {
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 16px;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  &:hover {
    background-color: #F5F6F9;
  }
}
.menu-item {
  display: flex;
  text-decoration: none;
  color: #666E81 !important;
  &:hover {
    color: #262633 !important;
    font-weight: 600;
  }
  &:hover .doctors-link {
    color: #262633 !important;
    font-weight: 600;
  }
}
.contacts-container {
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 1023px) {
    flex-direction: row-reverse;
    gap: 0px;
  }
}
.phone-desktop-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  @media (max-width: 1023px) {
    display: none
  }
  &:hover .phone-text {
    color: #2D7FF9 !important;
  }
}
.phone-text {
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  text-decoration: none;
}
.phone-time {
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.whats-app-container {
  padding: 12px;
  border-radius: 16px;
  background-color: #F5F6F9;
  height: 48px;
  cursor: pointer;
  @media (max-width: 1023px) {
    padding: 8px;
    background-color: transparent;
    height: 40px;
  }
  @media (min-width: 1023px) {
    &:hover {
      background-color: #EDEEF3;
    }
  }
}
.icon-whats-app {
  width: 24px;
  height: 24px;
  margin: 0;
}
.phone-mobile-container {
  padding: 8px;
  height: 40px;
  @media (min-width: 1023px) {
    display: none;
  }
}
.icon-phone {
  color: #262633;
  width: 24px;
  height: 24px;
  margin: 0;
}
.active-link {
  color: #262633 !important;
  font-weight: 600;
}
.icon-doctor-arrow {
  font-size: 24px;
  margin: 0;
  color: #171E36;
  cursor: pointer;
}
.doctors-link {
  text-decoration: none;
  color: #666E81 !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.doctors-active-link {
  text-decoration: none;
  color: #262633 !important;
  font-weight: 600;
  font-size: 1rem;
  font-style: normal;
  line-height: 125%;
}
.clinics-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  padding: 2px 8px;
  margin-left: 8px;
  background-color: #2D7FF9;
  color: white;
  border-radius: 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
</style>
